import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { Alert, Button, Modal, Nav } from "react-bootstrap";
import i18n from "eth-common/i18n/i18n";
import { Link } from "react-router-dom";
import { FetchWithAuth } from "digimaker-ui/util";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { EthContext } from "eth-common/EthContext";

let currentIndex = "";

export const Settings = () => {
  const [images, setImages] = useState({} as any);
  const fileInput = useRef<HTMLInputElement>(null);
  const [currentCrop, setCurrentCrop] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [currentSize, setCurrentSize] = useState([0, 0]);
  const ctx = useContext(EthContext) as any;

  const calculateSize = () => {
    let height = crop.width / crop.aspect;
    let ratio = height / 600;

    let targetWidth = currentSize[0] * ratio;
    let targetHeight = currentSize[1] * ratio;
    return [Math.round(targetWidth), Math.round(targetHeight)];
  };

  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 100,
    y: 100,
    aspect: 0.686,
    width: 200,
  });

  const initImages = {
    main: "pdf-assets/coverbg_" + ctx.currentYear + ".jpg",
    step3: "pdf-assets/coverimages/step3.jpg",
    step4: "pdf-assets/coverimages/step4.jpg",
    step5: "pdf-assets/coverimages/step5.jpg",
    step6: "pdf-assets/coverimages/step6.jpg",
    step7: "pdf-assets/coverimages/step7.jpg",
    step8: "pdf-assets/coverimages/step8.jpg",
  };

  useEffect(() => {
    FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL + "/eth/user/fetch-coverimage",
      {
        method: "POST",
        body: "",
      }
    ).then((data) => {
      let list = data.data;
      let images = { ...initImages };
      for (let i in list) {
        images[list[i].identifier] = list[i].image;
      }

      setImages(images);
    });
  }, []);

  const clickUpload = (key: string) => {
    let o = fileInput.current;
    if (o) {
      currentIndex = key;
      o.click();
    }
  };

  const uploadImage = (ele) => {
    const file = ele.target.files[0];
    var _URL = window.URL || window.webkitURL;
    var newImage = new Image();
    var objectUrl = _URL.createObjectURL(file);
    newImage.onload = function () {
      let size = [newImage.width, newImage.height];
      if (size[0] < 1000) {
        window.alert("Width should be more than 1000.");
      } else if (size[0] > size[1]) {
        window.alert("Width can not be more than height.");
      } else {
        setCurrentSize(size);
        setCurrentCrop(objectUrl);
      }
      _URL.revokeObjectURL(objectUrl);
    };
    newImage.src = objectUrl;
  };

  const sendImage = () => {
    let file = fileInput.current;
    if (file && file.files) {
      const formData = new FormData();
      formData.append("file", file.files[0]);
      formData.append("crop_base_height", "600");
      formData.append("identifier", currentIndex + "");
      let cropHeight = crop.width / crop.aspect;
      let ratio = currentSize[1] / 600;
      formData.append("crop_x", crop.x * ratio + "");
      formData.append("crop_y", crop.y * ratio + "");
      let realHeight = cropHeight * ratio;
      let realWidth = crop.width * ratio;
      formData.append("crop_width", realWidth + "");
      formData.append("crop_height", realHeight + "");

      FetchWithAuth(
        process.env.REACT_APP_REMOTE_URL + "/eth/user/upload-coverimage",
        {
          method: "POST",
          body: formData,
        }
      ).then((data) => {
        if (data.error === false) {
          let path = data.data;
          let newImages = { ...images };
          newImages[currentIndex] = path;
          setCurrentCrop("");
          setImages(newImages);
        } else {
          window.alert(data.data.message);
        }
      });
    }
  };

  const resetImage = (type) => {
    FetchWithAuth(
      process.env.REACT_APP_REMOTE_URL +
        "/eth/user/reset-coverimage?identifier=" +
        type,
      {
        method: "POST",
      }
    ).then((data) => {
      if (data.error === false) {
        let imagesNew = { ...images };
        imagesNew[type] = initImages[type];
        setImages(imagesNew);
      } else {
        window.alert(data.data.message);
      }
    });
  };

  const imageDiv = useRef<HTMLDivElement>(null);

  const showCrop = (path: string) => {
    setCurrentCrop(path);
  };

  const preview = (previewUrl: string) => {
    setPreviewUrl(previewUrl);
  };

  let imageDivSize = [];

  return (
    <div>
      <div className="right">
        <Link to="/myreport">
          <i className="fas fa-arrow-circle-left"></i> Tilbake
        </Link>
      </div>

      <div>
        <h2>{i18n.t("Report images")}</h2>

        <div className="right" style={{ margin: "10px 20px", fontSize: "95%" }}>
          <Alert variant="warning">{i18n.t("Report image text")}</Alert>
        </div>
        <Modal
          show={currentCrop ? true : false}
          onHide={() => setCurrentCrop("")}
          dialogClassName="modal-800"
        >
          <Modal.Header translate={""} closeButton>
            Crop image
          </Modal.Header>
          <Modal.Body>
            <div className="image-crop">
              <div ref={imageDiv} style={{ display: "inline-block" }}>
                <ReactCrop
                  ref={imageDiv}
                  crop={crop}
                  onChange={(c: any) => {
                    // if (imageDiv.current) {
                    //   const maxWidth = imageDiv.current.clientWidth;
                    //   const maxHeight = imageDiv.current.clientHeight;

                    //   const xleft = c.x;
                    //   const xRight = c.x + c.width;
                    //   const yTop = c.y;
                    //   const yBottom = c.y + c.height;
                    //   if (
                    //     xleft >= 1 &&
                    //     xRight <= maxWidth - 3 &&
                    //     yTop >= 1 &&
                    //     yBottom <= maxHeight - 3
                    //   ) {
                    setCrop(c);
                    //   }
                    // }
                  }}
                  rat
                  src={currentCrop}
                ></ReactCrop>
              </div>
            </div>

            <div style={{ position: "absolute", top: "0px", left: "20px" }}>
              width: {calculateSize()[0]}, height: {calculateSize()[1]}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={sendImage}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={previewUrl ? true : false}
          onHide={() => setPreviewUrl("")}
        >
          <Modal.Header translate={""} closeButton></Modal.Header>
          <Modal.Body>
            <img style={{ maxWidth: "100%" }} src={previewUrl} />
          </Modal.Body>
        </Modal>

        <div>
          <input
            style={{ display: "none" }}
            ref={fileInput}
            accept=".png,.jpg"
            type="file"
            onChange={uploadImage}
          />
          <table className="table">
            {Object.keys(images).map((key: string) => (
              <tr>
                <td>{i18n.t("step-" + key)}:</td>
                <td>
                  <div className="coverimage-upload">
                    <img
                      height="100px"
                      onClick={() => preview("/var/" + images[key])}
                      src={"/var/" + images[key]}
                    />
                  </div>
                </td>
                <td style={{ width: "100px" }}>
                  {images[key] != initImages[key] && (
                    <button
                      onClick={() => {
                        resetImage(key);
                      }}
                      className="btn btn-button"
                    >
                      {" "}
                      {i18n.t("Reset")}
                    </button>
                  )}
                </td>
                <td>
                  <button
                    onClick={() => clickUpload(key)}
                    className="btn btn-primary"
                  >
                    {" "}
                    {i18n.t("Upload")}
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};
